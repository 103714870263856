.banner_container{
    width: 100%;
    height: 350px;
    background-image: url('./images/bg.jpg');
    background-position: center;
    background-size: cover;
    background-size: 100% 100%;
    padding: 0px;
    margin: 0px;
}
.banner_overlay{
    width: 100% !important;
    height: 350px;
    background-color: #0000007d;
    background-repeat: repeat;
    position: absolute;
    top: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.overlay_title{
    font-family: 'Poppins';
    color: #FFFFFF;
}
.desert_safari_main_container{
    width: 100%;
    min-height: 800px;
}
.desert_safari_main_col{
    width: 100%;
    min-height: 900px;
    
   
}
.desert_safari_inner_item{
    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    border-radius: 20px;
  
    margin-top: 40px;
   padding-bottom: 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.desert_safari_inner_item:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.item_image{
    height: 300px !important;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    transition: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.item_image::before{
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.item_image:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}

.item_package_name{
    width: 90%;
    margin-left: 5%;
    margin-top: 20px;
    margin-bottom: 5px;
}
.item_package_title{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
}
.item_price_container{
    width: 90%;
    margin-left: 5%;
  
    height: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
   
}
.offer_price{
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: bold;
}
.orignal_price{
      font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
   text-decoration-line: line-through;
    color: #FA3E2C;
    margin-left: 15px;
}
.item_review_container{
       width: 90%;
    margin-left: 5%;
    margin-bottom: 5px;
    height: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
    
}
.review_text{
     font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
    margin-top: 7px;
}
.item_itineary_container{
    width: 90%;
    margin-left: 5%;
   
}
.inner_itineary{
    width: 100%;
    height: 20px;
    margin-top: 2.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.item_btn_container{
    width: 90%;
    margin-left: 5%;
    height: 60px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
}
.item_btn{
    width: 49%;
    height: 45px;
    font-family: 'Poppins';
    color: #FFFFFF;
    border-width: 0px;
    border-color: transparent;
     background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
}
.desert_safari_main_col_top_10{
    margin-top: 20px !important;
}
.calculator_container{
    width: 100%;
    height: auto;
    background-color: #FBFBFB;
    border-radius: 8px;
    margin-top: 50px;
    padding-top: 10px;
}
.price_container{
    width: 100%;
    height: 110px;
    padding: 12px;
}
.max_price{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: #FA3E2C;
}

.discounted_price{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1D293F;
}

.discounted_price_left{
      font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 48px;
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: #1D293F;
}

.per_person{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #546179;
}
.per_person_mobile{
      font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #546179;
    margin-left: 10px;
}

.off_percentage{
  
width: 101px;
height: 38px;
background: #28b0a784;
border-radius: 24px;
display: flex;
justify-content: center;
align-items: center;


}
.off_text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #28B0A6;
}
.note_description{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: #99A3AD;
}
.form_container{
     width: 100%;
   padding: 12px;
}
.form_container_mobile{
       width: 100%;
   padding: 12px;
   margin-top: 80px;
}
.check_availability{
    width: 100%;
    height: 52px;
    background: linear-gradient(90deg, #FF9D4B 9.15%, #FA126C 101.52%);
    border-radius: 8px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    transition: 1s ease-in-out;
  
}
.check_availability:hover{
background: linear-gradient(90deg, #FA126C 9.15%, #FF9D4B 101.52%);
}
.modal_bg{
    width: 100%;
    height: 200px;
}
.modal_top{
    width: 100%;
    height: 240px;
    position: absolute;
    background-color: transparent;
    top: 50;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    padding: 10px;
}
.congratulation_heading{
    font-size: 24px;
    font-weight: bold;
    margin-top: 30px;
    font-family: 'Poppins';
}
.available_text{
    font-size: 18px;
    font-weight: 600;
    font-family: 'Poppins';
}
.modal_bg_image{
    width: 50%;
    margin-left: 25%;
    margin-top: 30px;
}